@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: left;

}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}